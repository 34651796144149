const categories = [
  {
    icon: "mobile-alt",
    link: "Add Mobile"
  },
  {
    icon: "envelope",
    link: "Add Email"
  },
  {
    icon: "credit-card",
    link: "Add Card"
  },
  {
    icon: "university",
    link: "Add Bank Account"
  }
]

export default categories