import React from "react"
import {Wrapper, NavLink, Row, Column} from "./styles"
import links from "./links"

function FooterLinks() {
  return (
      <>
        <Row>
          {links.map((link, index) => (
            <Column>
              <NavLink key={index} href={link.href}>
                {link.title}
              </NavLink>
            </Column>
          ))}
        </Row>
      </>
  )
}

export default FooterLinks
