import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: ${({paddingTop}) => (paddingTop ? '15px' : '0')};
  flex-direction: ${({imgStart}) => (imgStart ? 'row-reverse' : 'row')};
  width: 100%;
  //min-height: 160px;
  max-height: 300px;
`

export const Column = styled.div`
  //margin: 0 15px 15px 15px;
  //padding-right: 15px;
  //padding-left: 15px;
  flex: 1;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`

export const ImgWrapper = styled.div`
  width: auto;
  display: flex;
  flex-direction: row-reverse;
`

export const Img = styled.img`
  padding-right: 0;
  margin-bottom: 10px;
  display: inline-block;
  max-height: 130px;
`

export const TextWrapper = styled.div`
  float: right;
  display: inline-flex;
  flex-direction: column;
`

export const Text = styled.p`
  text-align: ${({ textRight }) => (textRight ? 'right' : 'left')};
margin: 0;
`
