import React, {Fragment, useState, useContext} from "react"
import {Link} from "@reach/router"
import {Contents} from "components/common/"
import {Wrapper, Logo, Nav} from "./styles"
import logo from "assets/images/logo_love.png"
import Hamburger from "./Hamburger"
import NavbarLinks from "./NavbarLinks"

import LoginSignout from "./LoginSignout"
import Drawer from "./Drawer"

function NavBar() {
    const [toggle, setToggle] = useState(false)
    return (
        <Fragment>
            <Wrapper as={Contents}>
                <Logo>
                    <Link to="/">
                        <img src={logo} alt="Love Logo" width="150px"/>
                    </Link>
                </Logo>
                <Hamburger setToggle={() => setToggle(!toggle)} toggle={toggle}/>
                <Nav>
                    <NavbarLinks/>
                </Nav>
            </Wrapper>
            <Drawer toggle={toggle}/>
        </Fragment>
    )
}

export default NavBar
