import React, {Fragment} from "react"

import {features, icons} from "./features"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
    Header,
    Footer,
    SEO,
    Wrapper, Contents, Row,
} from "components/common"
import {Heading, Feature, Title, Description, FeatureIcon} from "./styles"

export function ContactUs() {
    return (
        <Fragment>
            <SEO title="Contact Us" />
            <Header />
            <Wrapper>
                <Contents>
                    <Heading >Contact Us</Heading>
                    <Row>
                        {features.map((feature, index) => (
                            <Feature key={index}>
                                <Title>{feature.title}</Title>
                                <Description>{feature.description1}</Description>
                                <Description>{feature.description2}</Description>
                                <Description>{feature.description3}</Description>
                            </Feature>
                        ))}
                    </Row>
                    <Row>
                        {icons.map((icon, index) => (

                            <FeatureIcon key={index}>
                                <a target="_blank" rel="noreferrer" href={icon.link}>
                                <FontAwesomeIcon
                                    icon={["fab", icon.icon]}
                                    size="3x"
                                    transform="shrink-1"
                                    color="#DA1A5E"
                                />
                                <Title>{icon.title}</Title>
                                </a>
                            </FeatureIcon>

                        ))}
                    </Row>
                </Contents>
            </Wrapper>
            <Footer />
        </Fragment>
    )
}


