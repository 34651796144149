import React from "react"
import {Column, Copy, Name} from "./styles"

function Copyright() {
  return (
      <Column>
          <Copy>
              Copyright © {new Date().getFullYear()} <Name href="#">PT. Madame Express Remitindo</Name>.
              All Rights Reserved.
          </Copy>
      </Column>
  )
}

export default Copyright
