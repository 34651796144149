import { Contents, Heading, Img, Row, SubHeading, Wrapper } from "./styles";
import logoCPSeafood from "assets/images/partner_CPSeafood.png"
import logoDanDan from "assets/images/partner_dandan.png"
import logoFaspay from "assets/images/partner_faspay.png"
import logoIndomaret from "assets/images/partner_indormaret.png"
import logoSyb from "assets/images/partner_syb.png"
import logoPFM from "assets/images/partner_pfm.png"
import logoUnipin from "assets/images/partner_unipin.png"
import logoVsi from "assets/images/partner_vsi.png"
import logoAlto from "assets/images/partner_alto.png"
import logoMoneyGram from "assets/images/partner_moneygram.png"
import logoWU from "assets/images/partner_wu.png"
import logoTranglo from "assets/images/partner_tranglo.png"
import logoThunes from "assets/images/partner_thunes.jpg"
import logoBniSr from "assets/images/partner_bni_sr.jpg"
import logoTransfast from "assets/images/transfast.jpg"
import logoFlip from "assets/images/partner_flip.png"
import logoBca from "assets/images/partner_bca.png"
import logoBri from "assets/images/partner_bri.png"
import logoPermata from "assets/images/partner_permata.png"
import logoBni from "assets/images/partner_bni.png"
import logoMandiri from "assets/images/partner_mandiri.png"
import logoChipSakti from "assets/images/partner_chipsakti.png"
import logoKiosBank from "assets/images/partner_kiosbank.png"
import logoMai from "assets/images/partner_mai.png"
import logoDaring from "assets/images/partner_daring.png"
import logoMasago from "assets/images/partner_masago.png"
import logoKitaBisa from "assets/images/partner_kitabisa.png"
import wilopo from "assets/images/Wilopo.jpg"
import CpPrima from "assets/images/CP_Prima.png"
import cpi from "assets/images/cpi.jpg"
import collabAsia from "assets/images/collab_asia.png"
import lightnet from "assets/images/Lightnet.png"
import wu from "assets/images/WU.png"
import logotraswap from "assets/images/Transwap.png"
import logoGloglo from "assets/images/Gloglo.jpg"
import partner1 from "assets/images/partner1.png"
import partner2 from "assets/images/partner2.png"
import partner3 from "assets/images/partner3.png"
import { Bundar, PartnerShip, Box,LabelPartner } from 'components/common';

export const OurPartners = () => {
    return (
        <>
            <Wrapper bgColor="#ffffff" padding="3rem 0">
                <Contents>
                    <Heading style={{ marginBottom: "20px" }}>
                        Partnership footprint for GROWTH together
                    </Heading>

                    <PartnerShip>
                        <Box>
                            <LabelPartner>Business Solutions</LabelPartner>
                            <Bundar src={partner1} />
                        </Box>
                        <Box>
                            <LabelPartner>International Connection</LabelPartner>
                            <Bundar src={partner2} />
                        </Box>
                        <Box>
                            <LabelPartner>Channel & Last-Mile Network</LabelPartner>
                            <Bundar src={partner3} />
                        </Box>
                    </PartnerShip>
                </Contents>
            </Wrapper>
        </>
    );
}