import React from "react"
// import FooterLinks from "./FooterLinks"
// import SocialLinks from "./SocialLinks"
// import Copyright from "./Copyright"
// import PrivacyLinks from "./PrivacyLinks"
import {Column, Divider, Row, Wrapper} from "./styles"
import {CompanyLinks} from "./CompanyLinks";
import FooterLinks from "./FooterLinks";
import Copyright from "./Copyright";
import {SubscribePanel} from "./SubscribePanel";


export const Footer = () => {
  return (
        <Wrapper bgColor="#DA1A5E">
          <Row>
            <Column>
                <SubscribePanel/>
            </Column>
            <Column maxWidth='40%'>
                <FooterLinks/>
            </Column>
            <Column>
                <CompanyLinks/>
            </Column>
          </Row>
          <Row>
              <Divider>
                  <Row>
                      <Copyright/>
                  </Row>
              </Divider>
          </Row>
        </Wrapper>
  )
};