import React from "react"
import { Wrapper, Contents, Row, ButtonSecondary } from "components/common"
import { FixedBackground, Heading, SubHeading } from "./styles"

function AwesomeCustomerSupport() {
  return (
    <Wrapper as={FixedBackground}>
      <Contents>
        <Heading>About Love</Heading>
        <SubHeading style={{fontSize:"15px"}}>
        LOVE REMIT is a brand of PT MADAME EXPRESS REMITINDO, a remittance company licensed by the Central Bank of &nbsp; Indonesia 
        (BI Licensed 19/207/DKSP/73) providing individual and corporate payment solutions that are easy, fast, secure, affordable and transparent. 
        LOVE Remit covers payments to 25+ countries and currencies.
        </SubHeading>
      </Contents>
    </Wrapper>
  )
}

export default AwesomeCustomerSupport
