import {ButtonEmail, Column, InputEmail, Row, Text, TextWrapper} from "./styles";

export const SubscribePanel = () => {
    return (
        <>
            <Row>
                <Column>
                    <TextWrapper>
                        <Text>Subscribe to get the latest information</Text>
                        <InputEmail type="email" />
                        <ButtonEmail>SUBSCRIBE</ButtonEmail>
                    </TextWrapper>
                </Column>
            </Row>
        </>
    );
}