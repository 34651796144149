const links = [
  {
    title: "About Us",
    href: "#"
  },
  {
    title: "Prices",
    href: "#"
  },
  {
    title: "Contact Us",
    href: "#"
  },
  {
    title: "FAQ",
    href: "#"
  },
  {
    title: "Support",
    href: "#"
  },
  {
    title: "Help",
    href: "#"
  },
  {
    title: "Careers",
    href: "#"
  },
  {
    title: "Affiliate",
    href: "#"
  },
  {
    title: "Fees",
    href: "#"
  }
]

export default links;