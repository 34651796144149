import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor};
  align-items: center;
  padding: 3.5rem 0;

  @media (min-width: 992px) {
    padding: ${({ padding }) => padding ?? "5rem 0"};
  }
`

export const Contents = styled.div`
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1170px;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  @media(max-width: 800px) {
        flex-direction: column;
    }
`
export const RowBorder = styled.div`
  border-style: solid;
  border-color: #DA1A5E;
  border-radius: 20px;
  margin: auto;
  font-stretch: expanded;
  @media(max-width: 800px) {
    margin: 10px auto;
    width: 240px;
    height: 220px;
  }
  @media(min-width: 1366px) {
    height: 220px;
    width: 220px;
  }
`

export const RowCenter = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  margin-top: 2rem;
`

export const Line = styled.div`
  display: block;
  border: 1px  grey;
  border-bottom: 4px solid #ccc;
 
  
`


export const Col = styled.div`
  display: ${({ flexSM }) => flexSM === "0" ? "none" : "block"};
  flex: ${({ flexSM }) => flexSM ? flexSM : "1 1 100%"};
  position: relative;
  width: fit-content;
  padding-top: 15px;
  padding-left: ${({ noPaddingLeft }) => noPaddingLeft ? "0" : "15px"};
  padding-right: ${({ noPaddingRight }) => noPaddingRight ? "0" : "15px"};

  @media (min-width: 768px){
    flex: ${({ flexMD }) => flexMD ?? "auto"};
  }

  @media (min-width: 992px){
    display: block;
    flex: ${({ flex }) => flex ?? "auto"};
  }
`


export const PartnerShip = styled.div`
  display: flex;
  flex-direction: row;  
  margin-top: 40px;
  @media(max-width: 800px) {
    flex-direction: column;
  }
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;
`
export const LabelPartner = styled.label`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 20px;
  @media(max-width: 800px) {
    margin-top: 40px;
  }
`
export const Bundar = styled.div`
  border: 1px solid;
  border-color: #DA1A5E;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  margin: auto;
  background-color: pink ;
  background-position: center;
  background-size: cover;
  box-shadow: 2px 3px #DA1A5E;
  background-image: ${({ src }) => `url(${src})`};
`

