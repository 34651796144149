import React from "react"
import data from "./data"
import {Wrapper, RowCenter} from "components/common"
import {Heading, ContentsCenter,ContentWraps, Feature, ColCenter, Description} from "./styles"


function EasyWays() {
    return (
        <ContentWraps>
            <ColCenter>
                <Heading>Cara Mudah Menerima Uang</Heading>
                <RowCenter >
                    {data.map((feature, index) => (
                        <Feature key={index} >
                                <img
                                    src={feature.img}
                                    alt="Love Icon"
                                    width="150px"
                                />
                            <ColCenter>
                                <Description>{feature.description}</Description>
                            </ColCenter>

                        </Feature>
                    ))}
                </RowCenter>
            </ColCenter>
        </ContentWraps>
    )
}

export default EasyWays
