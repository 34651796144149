import React, {Fragment} from "react"
import Intro from "./Intro"
import HomeTop from "./HomeTop";
import WhyShouldYouChoosePayyed from "./WhyShouldYouChoosePayyed"
import PaymentSolutionsForAnyone from "./PaymentSolutionsForAnyone"
import WhatCanYouDoWithPayyed from "./WhatCanYouDoWithPayyed"
import HowDoesItWork from "./HowDoesItWork"
import WhatPeopleAreSayingAboutPayyed from "./WhatPeopleAreSayingAboutPayyed"
import AwesomeCustomerSupport from "./AwesomeCustomerSupport"
import GetTheApp from "./GetTheApp"
import SendMoneyWithLove from "./SendMoneyWithLove"
import EasyStepToSendMoney from "./EasyStepToSendMoney"
import EasyWays from "./EasyWayToGetMoney";
import  Slider from "./Slider"
import LoveProduct from "./LoveProduct"
import Aboutlove from "./AboutLove"
import GetLoveApp from "./GetLoveApp"

import {Header, Footer, SEO, Line} from "components/common"
import {OurPartners} from "./OurPartners";

export function Landing() {
    return (
        <Fragment>
            <SEO/>
            <Header/>
            {/*<HomeTop/>*/}
            {/*<SendMoneyWithLove/>*/}
            {/*<Line/>*/}
            {/*<EasyStepToSendMoney/>*/}
            {/*<Line/>*/}
            {/*<EasyWays/>*/}
            <Slider/>
            <LoveProduct/>
            <Aboutlove/>
            <OurPartners/>
            <GetLoveApp/>



            {/*<Intro/>*/}
            {/*<WhyShouldYouChoosePayyed/>*/}
            {/*<PaymentSolutionsForAnyone/>*/}
            {/*<WhatCanYouDoWithPayyed/>*/}
            {/*<HowDoesItWork/>*/}
            {/*<WhatPeopleAreSayingAboutPayyed/>*/}
            {/*<AwesomeCustomerSupport/>*/}
            {/*<GetTheApp/>*/}
            <Footer/>
        </Fragment>
    )
}
