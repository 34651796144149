import styled from "styled-components"

// export const Wrapper = styled.div`
//   position: relative;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   margin-right: auto;
//   padding-right: 15px;
//   padding-left: 15px;
//   max-width: 410px;
//   width: 100%;
//
//   @media (min-width: 768px){
//     padding-right: 0;
//     padding-left: 0;
//   }
// `

export const Wrapper = styled.div`
  width: 100%;
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  //align-items: center;
  padding-top: ${({paddingTop}) => (paddingTop ? '15px' : '0')};
  flex-direction: ${({imgStart}) => (imgStart ? 'row-reverse' : 'row')};
  //width: 100%;
  width: auto;
`

export const Column = styled.div`
  margin: 0 15px 20px 15px;
  //padding-right: 15px;
  //padding-left: 15px;
  flex: 1;
  flex-basis: 20%;
  //max-width: 100%;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`

export const NavLink = styled.a`
  font-size: 1rem;
  color: #ffffff;
  transition: all 0.2s ease;
  font-weight: 500;

  &:hover,
  &:focus {
    text-decoration: none;
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
  }
`