export const features = [
  {
    title: "Alamat",
    description1: "Puri Matari 1 UG Floor",
    description2: "Jl. HR. Rasuna Said Kav. H1-H2",
    description3: "Kuningan Setiabudi, South Jakarta - 12920.",
  },
  {
    title: "Our Contact",
    description1: "TEL: (+62) 21 5091-7007.",
    description2: "B2B EMAIL: b2b@lovepayment.id.",
    description3: "WHATSAPP NUMBER: (+62) 813-8860-1508 .",
  }
]

export const icons = [
  {
    icon: "instagram",
    title: "Instagram",
    link: "https://www.instagram.com/loveid_untung",
  },
  {
    icon: "facebook-f",
    title: "Facebook",
    link: "https://www.facebook.com/lovepaymentid",
  },
  {
    icon: "linkedin",
    title: "LinkedIn",
    link: "https://www.linkedin.com/company/love-id/",
  },
  {
    icon: "tiktok",
    title: "TikTok",
    link: "https://www.tiktok.com/@loveid_untung?lang=en",
  },

]