import styled from 'styled-components'

export const Heading = styled.h2`
  font-size: 2.25rem;
  text-align: center;
`

export const SubHeading = styled.p`
  font-size: 1.125rem;
  text-align: center;
  margin-bottom: 3rem;
`

export const Feature = styled.div`
  &:nth-child(-n+3){
    margin-bottom: 1.5rem;
  }

  @media (min-width: 1024px){
    flex-basis: 25%;
    max-width: 75%;
    &:nth-child(-n+3){
      margin-bottom: 0;
      
    }
  }
`

export const Title = styled.h3`
  text-align: center;
  margin-top: 10px;
  font-size: 20px;

  @media(max-width: 800px) {
    text-align: center;
    margin-top: 30px;
    font-size: 20px;
    }
`

export const Description = styled.div`
  text-align: center;
  font-style: italic;
  text-decoration: solid;
  margin-bottom: 20px;
  
  @media(max-width: 800px) {
    margin-top: 20px;
    font-size: 16px;
  }
`