import styled from "styled-components"

export const Column = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`

export const Copy = styled.p`
  text-align: center;
  margin: 0 auto 0;
  width: fit-content;
  color: #ffffff;
  font-weight: 400;

  //@media (min-width: 768px){
  //  margin: 0 auto 0 0;
  //}
`
export const Name = styled.a`
  color: #ffffff;
  
  &:hover,
  &:focus {
    text-decoration: none;
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
  }
`