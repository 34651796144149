import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: ${({paddingTop}) => (paddingTop ? '15px' : '0')};
  flex-direction: ${({imgStart}) => (imgStart ? 'row-reverse' : 'row')};
  width: 100%;
  //min-height: 160px;
  max-height: 300px;
`

export const Column = styled.div`
  //margin: 0 15px 15px 15px;
  //padding-right: 15px;
  //padding-left: 15px;
  flex: 1;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`

export const InputEmail = styled.input`
  background-color: #DA1A5E;
  border: 1px solid #ffffff;
  border-radius: 5px;
  color: #ffffff;

  input:focus {
    color: #ffffff;
  }
`

export const ButtonEmail = styled.button`
  margin: 5px 0;
  padding: 5px 0;
  color: #da1a5e;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
`

export const TextWrapper = styled.div`
  float: left;
  display: inline-flex;
  flex-direction: column;
`

export const Text = styled.p`
  text-align: ${({ textRight }) => (textRight ? 'right' : 'left')};
margin: 0;
`