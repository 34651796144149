import styled from 'styled-components'

export const Heading = styled.h2`
  font-size: 2.25rem;
  text-align: center;
  color: white;
  background-color: #DA1A5E;
`

export const SubHeading = styled.p`
  font-size: 1.125rem;
  text-align: center;
  margin-bottom: 3rem;
`

export const Feature = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  align-content: center;

  &:nth-child(-n+3){
    margin-bottom: 1.5rem;
  }

  @media (min-width: 1024px){
    flex-basis: 50%;
    max-width: 50%;
    &:nth-child(-n+3){
      margin-bottom: 0;
    }
  }
`
export const FeatureIcon = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
text-align: center;
  &:nth-child(-n+3){
    margin-bottom: 1.5rem;
  }

  @media (min-width: 900px){
    flex-basis: 25%;
    max-width: 33%;
    &:nth-child(-n+3){
      margin-bottom: 0;
    }
  }
`


export const Title = styled.h3`
  font-size: 20px;
  margin: 20px 0 10px;
  text-align: center;
`

export const Description = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
  text-align: center;
`

export const link = styled.a`
 color: black;
`