import styled from 'styled-components'

export const Heading = styled.h2`
  font-size: 2.25rem;
  text-align: center;
`

export const SubHeading = styled.p`
  font-size: 1.125rem;
  text-align: center;
`

export const Feature = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
  column-width: fill-available;
  justify-content: flex-start;
  justify-items: center;
  text-align: center;

  &:nth-child(-n+3){
    margin-bottom: 1.5rem;
  }

  @media (min-width: 1024px){
    flex-basis: 25%;
    max-width: 25%;
    &:nth-child(-n+3){
      margin-bottom: 0;
    }
  }
`

export const ColCenter = styled.div`
  display: ${({ flexSM }) => flexSM === "0" ? "none" : "block"};
  flex: ${({ flexSM }) => flexSM ? flexSM : "1 1 100%"};
  position: relative;
  width: fit-content;
  padding-top: 1rem;
  padding-left: ${({ noPaddingLeft }) => noPaddingLeft ? "0" : "15px"};
  padding-right: ${({ noPaddingRight }) => noPaddingRight ? "0" : "15px"};

  @media (min-width: 768px){
    flex: ${({ flexMD }) => flexMD ?? "auto"};
  }

  @media (min-width: 992px){
    display: block;
    flex: ${({ flex }) => flex ?? "auto"};
  }
`

export const Description = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
`

export const ContentsCenter = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 20px;
  margin-right: 20px;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1170px;
  }
`