export const home = [
  {
    to: "/send",
    ariaLabel: "Learn how to send funds to over 180 countries",
    title: "Send"
  },
  {
    to: "/receive",
    ariaLabel: "Learn how to send funds to over 180 countries",
    title: "Receive"
  },
  {
    to: "/about-us",
    ariaLabel: "Learn about our company",
    title: "About us"
  },
  {
    to: "/fees",
    ariaLabel: "Learn how we structure our service fees",
    title: "Fees"
  },
  {
    to: "/help",
    ariaLabel: "Check out our FAQ for any questions you may have",
    title: "Help"
  }
]

export const dashboard = [
  {
    to: "/",
    ariaLabel: "Home",
    title: "Home"
  },
  {
    to: "#",
    ariaLabel: "Product",
    title: "Product",
    dropdown: [
      {
        to: "https://loveremit.id/",
        ariaLabel: "Remittance",
        title: "Remittance"
      },
      {
        to: "#",
        ariaLabel: "Mass Payment",
        title: "Mass Payment"
      }
    ]
  },
  {
    to: "/contact-us",
    ariaLabel: "Contact Us",
    title: "Contact Us"
  },
  // {
  //   to: "/",
  //   ariaLabel: "Product",
  //   title: "Product"
  // },
  // {
  //   to: "/",
  //   ariaLabel: "Partners",
  //   title: "Partners"
  // },
  // {
  //   to: "/",
  //   ariaLabel: "Company",
  //   title: "Company"
  // },
  // {
  //   to: "/",
  //   ariaLabel: "Developer",
  //   title: "Developer"
  // },
  // {
  //   to: "/transactions",
  //   ariaLabel: "See your latest transactions",
  //   title: "Transactions"
  // },
  // {
  //   to: "/send-request-money",
  //   ariaLabel: "Send or request funds",
  //   title: "Send/Request"
  // },
  // {
  //   to: "/help",
  //   ariaLabel: "Check out our FAQ for any questions you may have",
  //   title: "Help"
  // }
]