import styled from "styled-components"

export const NavLink = styled.a`
  display: flex;
  font-family: "robotomedium", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #DA1A5E;
  float: right;
  padding: 0 0.85em;

  &:hover,
  &:focus {
    text-decoration: none;
    color: #DA1A5E;
    border-bottom: 1px solid ;
  }
  
  ${({ pathname, to }) =>
    pathname === to &&
    `
    text-decoration: none;
    color: #DA1A5E;
  `}
`;

export const RegularLink = styled.a`
  display: flex;
  font-family: "robotomedium", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #DA1A5E;
  float: right;
  padding: 0 0.85em;

  &:hover,
  &:focus {
    text-decoration: none;
    color: #DA1A5E;
    border-bottom: 1px solid ;
  }

  ${({ pathname, to }) =>
      pathname === to &&
      `
    text-decoration: none;
    color: #DA1A5E;
  `}
`

export const NavDropdown = styled.li`
  position: relative;
  display: contents;
  height: 100%;

  &:hover {
    
     >ul {
      display: flex;
       visibility: visible;
      right: 0;
      background-color: #ffffff;
      flex-direction: column;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.176);
      border: 0 none;
      padding: 10px 15px;
      min-width: 180px;
      margin: 0;
      width: 100%;
      z-index: 1;
    }
  }

  @media (min-width: 992px){
    display: flex;
  }
`
export const NavDropdownContent = styled.ul`
  display: none;
  position: relative;
  left: 0;
  top: 100%;
  width: 100%;
  margin: 10px auto;
  padding: 10px 0;
  z-index: 1;

  @media (min-width: 992px){
    position: absolute;
  }
  
`




