const features = [
  {
    icon: "hand-pointer",
    title: "LOVE REMIT",
    description: " \"Global connection network of Cross-Border solutions in removing the complexity of payment within Outward and Inward market toward its customers\""
  },
  {
    icon: "reply",
    flipIcon: "horizontal",
    title: "API BUSINESS",
    description: "\"Simple API through one connection for multiple countries and payouts connections\"",
  },
  {
    icon: "dollar-sign",
    title: "BILL PAYMENT",
    description: "\"To provide a better way of life for everyone through innovativ Technology\"",
  }
]

export default features