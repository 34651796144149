import logo from "assets/images/logowhite512px_footer.png"
import {Column, Img, ImgWrapper, Row, Text, TextWrapper} from "./styles";

export const CompanyLinks = () => {
    return (
        <>
            <Row>
                <Column>
                    <ImgWrapper>
                        <Img src={logo} rightPanel={true}/>
                    </ImgWrapper>
                    <TextWrapper>
                        <Text textRight={true}>PT. Madame Express Remitindo</Text>
                        <Text textRight={true}>Jl. Rasuna Said Kav. H1-2 Setiabudi</Text>
                        <Text textRight={true}>Jakarta Selatan - 12920</Text>
                    </TextWrapper>
                </Column>
            </Row>
        </>
    );
}