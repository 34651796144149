import React from "react"
import features from "./features"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Wrapper, Contents, Row, ButtonLink, RowBorder } from "components/common"
import { Heading, SubHeading, Feature, Title, Description } from "./styles"

function WhyChoose() {
  return (
    <Wrapper>
      <Contents>
        <Heading>Our Solution</Heading>
        {/* <SubHeading>
          Berikut 4 produk terbaik yang dimiliki love.
        </SubHeading> */}
        <Row>
          {features.map((feature, index) => (
            <RowBorder>
              <div key={index}>
                <Title>{feature.title}</Title>
                <Description>{feature.description}</Description>
              </div>
            </RowBorder>
          ))}
        </Row>
      </Contents>
    </Wrapper>
  )
}

export default WhyChoose
