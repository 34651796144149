import styled from "styled-components"

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e2e8ea;
  margin: 0.5rem 0 1rem;

  @media screen and (max-width: 768px){
    margin: 0.5rem 0 2rem;
  }
`

export const Wrapper = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  color: #fff;
  padding: 20px 0 20px;
  //min-height: 160px;
  //max-height: 300px;
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: ${({paddingTop}) => (paddingTop ? '15px' : '0')};
  flex-direction: ${({imgStart}) => (imgStart ? 'row-reverse' : 'row')};
  width: 100%;
  //min-height: 160px;
  //max-height: 300px;
`

export const Column = styled.div`
  margin: 0 15px 15px 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: ${({ maxWidth }) => (maxWidth ?? '30%')};

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`