import styled from "styled-components"

export const IFrame = styled.iframe`
  position: absolute;
  width: 100%;
  height: 420px;

  @media (min-width: 768px){
    height: 420px;
  }
  @media (min-width: 1024px){
    height: 450px;
  }
`

export const Video = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
  height: fit-content;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;

  @media(min-width: 1024px){
    flex-basis: 50%;
  }
`

export const Placeholder = styled.img`
  width: 100%;
  height: auto;
  border-radius: 0.25rem;
  filter: contrast(90%) brightness(87%);
`

export const PlayButton = styled.button`
  position: absolute;
  z-index: 1;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  background-color: #ffffff;
  border: none;
  `
export const AccessibleText = styled.span`
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
`

export const BigText = styled.p`
  font-size: 1.4rem;
  text-align: left;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: bold;
`

export const SmallText = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
  padding-left: 15px;
  padding-right: 15px;
`

export const GoogleStore = styled.img`
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 5px;
  width: 20%;
  cursor: pointer;
`

export const ColTable = styled.div`
  display:flex;
  border-style:solid;
  border-color: #2dbe60;
  width: fit-content;
  padding-top: 15px;
  padding-left: ${({ noPaddingLeft }) => noPaddingLeft ? "0" : "15px"};
  padding-right: ${({ noPaddingRight }) => noPaddingRight ? "0" : "15px"};

  @media (min-width: 768px){
    flex: ${({ flexMD }) => flexMD ?? "auto"};
  }

  @media (min-width: 992px){
    display: block;
    flex: ${({ flex }) => flex ?? "auto"};
  }
`