import manWithPhone from "assets/images/man-using-his-phone.webp"
import creativeOffice from "assets/images/creative-office.webp"
import womenWithLaptop from "assets/images/woman-using-her-laptop.webp"

const slides = [
  {
    title: "Global Networking of Cross Border Payment",
    descriptionOne:
      "Fast, Affordable, Secure.",
    image: manWithPhone,
    titleSize: "lg",
    alignContent: "Center"
  }
]
export default slides