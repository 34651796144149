import styled from 'styled-components'

export const Heading = styled.h2`
  font-size: 2.25rem;
  text-align: center;
`

export const SubHeading = styled.p`
  font-size: 1.125rem;
  text-align: center;
`

export const Arrow = styled.h1`
  font-size: 4rem;
  text-align: center;
`

export const Feature = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
  column-width: fill-available;
 text-align: center;
  

  &:nth-child(-n+3){
    margin-bottom: 1.5rem;
  }

  @media (min-width: 1024px){
    flex-basis: 20%;
    max-width: 20%;
    &:nth-child(-n+3){
      margin-bottom: 0;
    }
  }
`

export const Title = styled.h3`
  font-size: 20px;
  margin: 20px 0 10px;
  padding-bottom: 2rem;
`

export const Description = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
`

export const ColArrow = styled.div`
  display: flex;
  margin: 0;
  position: relative;
  width: fit-content;
  align-items: center;
  justify-items: center;
  height: 210px;
`

export const ColCenter = styled.div`
  display: ${({ flexSM }) => flexSM === "0" ? "none" : "block"};
  flex: ${({ flexSM }) => flexSM ? flexSM : "1 1 100%"};
  position: relative;
  width: fit-content;
  padding-top: 1rem;
  padding-left: ${({ noPaddingLeft }) => noPaddingLeft ? "0" : "15px"};
  padding-right: ${({ noPaddingRight }) => noPaddingRight ? "0" : "15px"};

  @media (min-width: 768px){
    flex: ${({ flexMD }) => flexMD ?? "auto"};
  }

  @media (min-width: 992px){
    display: block;
    flex: ${({ flex }) => flex ?? "auto"};
  }
`

export const ContentWraps = styled.div`
  display: flex;
  background-color: ${({ bgColor }) => bgColor};
  justify-content: space-evenly;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  
  @media (min-width: 1000px) {
    padding: ${({ padding }) => padding ?? "0.5rem 0"};
  }
`