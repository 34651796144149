import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor};
  align-items: center;
  padding: 3.5rem 0;

  @media (min-width: 992px) {
    padding: ${({ padding }) => padding ?? "5rem 0"};
  }
`

export const Contents = styled.div`
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1170px;
  }
`

export const Heading = styled.h2`
  font-size: 2.25rem;
  text-align: center;
`

export const SubHeading = styled.p`
  font-size: 1.125rem;
  text-align: center;
  margin-bottom: 1.5rem;
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ justify }) => justify ?? "flex-start"};
  //margin-left: -15px;
  //margin-right: -15px;
  margin: 25px -15px;
`

export const Img = styled.img`
  padding-right: 0;
  margin: 20px;
  /* display: inline-block; */
  max-height: ${({ maxHeight }) => (maxHeight ?? "auto")};
  
  @media screen and (max-width: 992px) {
    max-height: 55px;
  }
`