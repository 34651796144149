import React, { Fragment, useContext } from "react"
import { State } from "state"
import { Link, Location } from "@reach/router"
import {NavDropdown, NavDropdownContent, NavLink, RegularLink} from "./styles"
import { home, dashboard } from "./links"
import {faArrowAltCircleDown} from "@fortawesome/free-solid-svg-icons";

function NavbarLinks() {
  const { state } = useContext(State)

  return (
      <Location>
        {({ location }) => {
          if (state.authenticated) {
            return (
                <Fragment>
                  {dashboard.map(({ to, ariaLabel, title, dropdown}, index) => {
                    if (dropdown) {
                      return (
                          <NavDropdown>
                            <NavLink
                                key={index}
                                as={Link}
                                to={to}
                                aria-label={ariaLabel}
                                pathname={location.pathname}
                            >
                              {title}
                              <i className={faArrowAltCircleDown}/>
                            </NavLink>
                            <NavDropdownContent>
                              {dropdown.map(({to, ariaLabel, title}, index) => (
                                  <NavDropdown>
                                    <RegularLink
                                        target="_blank"
                                        rel="noreferrer"
                                        key={index}
                                        href={to}
                                        aria-label={ariaLabel}>
                                      {title}
                                    </RegularLink>
                                  </NavDropdown>
                              ))}
                            </NavDropdownContent>
                          </NavDropdown>
                      );
                    } else {
                      return (
                              <NavLink
                                  key={index}
                                  as={Link}
                                  to={to}
                                  aria-label={ariaLabel}
                                  pathname={location.pathname}
                              >
                                  {title}
                              </NavLink>

                      );
                    }
                  })}
                </Fragment>
            )
          }

          return (
              <Fragment>
                {home.map(({ to, ariaLabel, title }, index) => (
                    <NavLink
                        key={index}
                        as={Link}
                        to={to}
                        aria-label={ariaLabel}
                        pathname={location.pathname}
                    >
                      {title}
                    </NavLink>
                ))}
              </Fragment>
          )
        }}
      </Location>
  );
}

export default NavbarLinks
